import { formatDateToShortString, formatDateToWappi } from "../../utils/utils";

export const createBodyReport = (response) => {
  return {
    name: "Текущий отчет",
    action: {
      text: "Скачать в PDF",
      type: "secondary",
      selector: "button",
    },
    data: [
      {
        columnsCount: 3,
        items: [
          [
            {
              title: "Отчет",
              type: "text",
              content: `${response.name} - ${formatDateToShortString(
                response.start_date
              )} - ${formatDateToShortString(
                response.end_date
              )} от  ${formatDateToWappi(response.time)}`,
            },
            {
              title: "Количество сообщений",
              type: "text",
              content: response.totalMsgCount,
            },
          ],
          [
            {
              title: "Эмоциональная окраска сообщений",
              type: "ChartPie",
              content: [
                {
                  label: "Позитивные",
                  color: "#90BE6D",
                  value: response.positiveMsgCount,
                },
                {
                  label: "Негативные",
                  color: "#F94144",
                  value: response.negativeMsgCount,
                },
                {
                  label: "Нейтральные",
                  color: "#F9C74F",
                  value: response.neutralMsgCount,
                },
              ],
            },
          ],
          [
            {
              title: "Категории сообщений",
              type: "ChartBar",
              content: [
                {
                  label: "Информация",
                  color: "#90BE6D",
                  value: response.informationMsgCount,
                },
                {
                  label: "Жалоба",
                  color: "#F94144",
                  value: response.complaintMsgCount,
                },
                {
                  label: "Приветствие",
                  color: "#F8961E",
                  value: response.greetingMsgCount,
                },
                {
                  label: "Вопрос",
                  color: "#F9C74F",
                  value: response.questionMsgCount,
                },
                {
                  label: "Ответ",
                  color: "#F3722C",
                  value: response.answerMsgCount,
                },
                {
                  label: "Реклама",
                  color: "#2D9CDB",
                  value: response.advertisementMsgCount,
                },
              ],
            },
          ],
          [
            {
              title: "Самые активные участники",
              caption: "(по количеству сообщений)",
              type: "RatiosCompare",
              content: response.topAllUsers?.map((user) => ({
                label: user.senderName,
                value: user.count,
                percent: user.percent,
              })),
            },
          ],
          [
            {
              title: "Самые позитивные участники",
              caption: "(по количеству позитивных сообщений)",
              type: "RatiosCompare",
              content: response.topUsersPositive?.map((user) => ({
                label: user.senderName,
                value: user.count,
                percent: user.percent,
              })),
            },
          ],
          [
            {
              title: "Самые негативные участники",
              caption: "(по количеству негативных сообщений)",
              type: "RatiosCompare",
              content: response.topUsersNegative?.map((user) => ({
                label: user.senderName,
                value: user.count,
                percent: user.percent,
              })),
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Самые обсуждаемые позитивные темы с конкретикой",
              type: "GridTexts",
              content: response.topicsPositive?.map((t) => ({
                title: t.short,
                text: t.detailed,
                color: "#90be6d",
              })),
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Самые обсуждаемые нейтральные темы с конкретикой",
              type: "GridTexts",
              content: response.topicsNeutral?.map((t) => ({
                title: t.short,
                text: t.detailed,
                color: "#F9C74F",
              })),
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title:
                "Самые обсуждаемые негативные темы (главные проблемы дома) с конкретикой ",
              type: "GridTexts",
              content: response.topicsNeutral?.map((t) => ({
                title: t.short,
                text: t.detailed,
                color: "#F94144",
              })),
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Общие выводы",
              type: "GridTexts",
              content: response.summary?.map((t) => ({
                title: t.short,
                text: t.detailed,
                color: "#f1d724",
              })),
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Рекомендуемые действия",
              type: "GridTexts",
              content: response.recommendations?.map((t) => ({
                title: t.short,
                text: t.detailed,
                color: "#90be6d",
              })),
            },
          ],
        ],
      },
    ],
  };
};
